import React, { Fragment, useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import { ClickAwayListener } from "@material-ui/core";

import { bright_90, increaseBrightness } from "common/helpers/adjustColor";
import LanguageHelper from "common/helpers/language.helper";
import upsdkService from "common/services/upsdkService";
import configSelector from "common/store/selectors/configSelector";
import pageSelector from "common/store/selectors/pageSelector";
import upsdkSelector from "common/store/selectors/upsdkSelector";

import ChevronIcon from "../../assets/icons/chevron.icon";
import { BaseContext } from "../../context/BaseContext";
import { useDialog } from "../../context/DialogContext";
import Typography from "../typography/index.component";
import PopOver from "../popover/index.component";
import useRouteHook from "../../hooks/useRoute.hook";
import colorConstants from "common/constants.json";
import checkLightOrDark from "common/helpers/checkLightOrDark";
import "./index.component.scss";
import AndroidIcon from "../../assets/icons/android-icon.icon";
import AppleIcon from "../../assets/icons/apple-icon.icon";

function SubHeader({
  showAuthDialogCallback,
  primaryColor,
  config,
  primaryTextColor,
  secondaryTextColor,
  userData,
  upsdk,
}) {
  const { showDialog, hideDialog } = useDialog();
  const [popOverOpen, setPopOverOpen] = useState(false);
  const { isMobileView, isQrModeEnabled, userLogout, isQsrEnabled } =
    useContext(BaseContext);
  const { historyPush } = useRouteHook();

  // variables
  const { t, i18n } = useTranslation();
  const profilePageConfig = pageSelector.getProfilePage({ config });
  const leftLink = configSelector.getTopHeaderLeftLink({ config });
  const rightLink = configSelector.getTopHeaderRightLink({ config });
  const supportedLanguages = upsdkSelector.getSupportedLanguages({ upsdk });
  const initialLanguage = LanguageHelper.getLanguage(config);

  const [activeLanguage, setActiveLanguage] = useState(initialLanguage);
  const topbarConfig = configSelector.getTopbar({ config });
  const [topbarColors, setTopbarColors] = useState({});

  const topbarBackgroundColor =
    topbarConfig?.customizations?.backgroundColor ||
    increaseBrightness(primaryColor, bright_90);

  const lightOrDark = checkLightOrDark(topbarBackgroundColor);

  useEffect(() => {
    if (lightOrDark === "light") {
      setTopbarColors(colorConstants.colors.header.light);
    } else {
      setTopbarColors(colorConstants.colors.header.dark);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lightOrDark]);

  const onLanguageChangeHandler = (language) => {
    LanguageHelper.onLanguageChange(i18n, language);
    setActiveLanguage(language);
  };

  const handleLoginClick = () => {
    showAuthDialogCallback("signIn");
  };

  const handlePopOverToggle = () => {
    setPopOverOpen((prev) => !prev);
  };

  const handlePopOverClose = () => {
    setPopOverOpen(false);
  };

  const handleProfileLinkClick = () => {
    handlePopOverClose();
    historyPush("/profile");
  };

  const handleLogoutConfirm = () => {
    hideDialog();
    userLogout();
    upsdkService.logout(userData.phone);
  };

  const handleLogoutClick = () => {
    showDialog({
      title: "Confirm",
      buttonText: "Ok",
      buttonColor: primaryColor,
      description: t("common.logoutAlert"),
      onClickCallback: () => handleLogoutConfirm(),
    });
  };

  const renderUserLoggedInState = (topbarColors) => {
    const guestCheckout = userData.guest_checkout;
    return (
      <Fragment>
        <ClickAwayListener onClickAway={handlePopOverClose}>
          <div className="user-logged-in-state">
            <Typography
              variant="h4"
              weight="semiBold"
              className="user-name-link"
              fontColor={topbarColors.textColor}
              onClickCallback={handlePopOverToggle}
            >
              {userData.name || userData.phone}
            </Typography>
            <ChevronIcon
              fill={topbarColors.textColor}
              className="chevron-down"
              size={{ width: 16, height: 16 }}
            />
            <PopOver
              open={popOverOpen}
              className="user-loggedin-state-popover"
              style={i18n.dir() === "rtl" ? { left: "0px" } : { right: "0px" }}
            >
              {!guestCheckout && (
                <Fragment>
                  {!isQsrEnabled && profilePageConfig.enabled && (
                    <Typography
                      variant="h4"
                      weight="semiBold"
                      className="menu-link"
                      fontColor={primaryTextColor}
                      onClickCallback={handleProfileLinkClick}
                    >
                      {t("subHeader.profile")}
                    </Typography>
                  )}
                  <Typography
                    variant="h4"
                    weight="semiBold"
                    className="menu-link"
                    fontColor={primaryTextColor}
                    onClickCallback={handleLogoutClick}
                  >
                    {t("subHeader.logout")}
                  </Typography>
                </Fragment>
              )}
            </PopOver>
          </div>
        </ClickAwayListener>
      </Fragment>
    );
  };

  const renderLanguageSelector = () => {
    return (
      <div className="language-selector">
        {supportedLanguages.map((lang, index) => (
          <Fragment key={lang}>
            {index !== 0 && (
              <Typography
                variant="h4"
                weight="semiBold"
                className="language-divider"
                fontColor={primaryTextColor}
              >
                |
              </Typography>
            )}
            <Typography
              variant="h4"
              weight="semiBold"
              fontColor={
                activeLanguage === lang
                  ? primaryTextColor
                  : topbarColors.textColor
              }
              onClickCallback={() => onLanguageChangeHandler(lang)}
            >
              {lang}
            </Typography>
          </Fragment>
        ))}
      </div>
    );
  };

  return (
    <div
      className="sub-header"
      style={{ backgroundColor: topbarBackgroundColor }}
    >
      <div className="container sub-header-container">
        <div className="left-section">
          {!isQsrEnabled ? (
            <React.Fragment>
              {(leftLink.androidAppLink || leftLink.iOSAppLink) && (
                <Typography
                  variant="h4"
                  weight="semiBold"
                  fontColor={topbarColors.textColor}
                >
                  {leftLink.title}
                </Typography>
              )}
              {leftLink.androidAppLink && (
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={leftLink.androidAppLink}
                  className="download-app-link"
                >
                  <AndroidIcon fill={topbarColors.iconColor} />
                </a>
              )}
              {leftLink.iOSAppLink && (
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={leftLink.iOSAppLink}
                  className="download-app-link"
                >
                  <AppleIcon fill={topbarColors.iconColor} />
                </a>
              )}
            </React.Fragment>
          ) : null}
        </div>
        <div className="right-section">
          {supportedLanguages.length > 1 && renderLanguageSelector()}

          {(!isQrModeEnabled || isQsrEnabled) && (
            <Fragment>
              {!isQsrEnabled ? (
                <a href={`tel:${rightLink.link}`} className="contact-us-link">
                  <Typography
                    variant="h4"
                    weight="semiBold"
                    fontColor={topbarColors.textColor}
                  >
                    {rightLink.title}
                  </Typography>
                </a>
              ) : null}

              {!isMobileView && (
                <>
                  {!userData || userData.guest_checkout ? (
                    <div className="auth-wrapper">
                      <Typography
                        variant="h4"
                        weight="semiBold"
                        className="login-link"
                        fontColor={topbarColors.textColor}
                        onClickCallback={handleLoginClick}
                      >
                        {t("subHeader.login")}
                      </Typography>
                    </div>
                  ) : (
                    renderUserLoggedInState(topbarColors)
                  )}
                </>
              )}
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    upsdk: state.upsdk,
    bizInfo: upsdkSelector.getBizInfo(state),
    selectedStore: upsdkSelector.getStore(state),
    config: state.config,
  };
}

export default connect(mapStateToProps, null)(SubHeader);
